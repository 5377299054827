import { GetStaticProps } from "next";

import { LoginTemplate } from "@/components";
import { RootLayout } from "@/layout/root-layout";
import { NextPageWithLayout } from "@/pages/_app";
import { getI18nStaticProps } from "@/utils/i18n";

export const getStaticProps: GetStaticProps = getI18nStaticProps;

const LoginPage: NextPageWithLayout = () => (
  <>
    <LoginTemplate />
  </>
);

export default LoginPage;

LoginPage.getLayout = (page) => <RootLayout>{page}</RootLayout>;
